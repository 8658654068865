class Application
  qrcode: 'mailto:nikolay@lavirko.ru'
  constructor: () ->
    @createQR()

    $(window).on 'resize', () =>
      @resize()

    $(window).trigger 'resize'

  createQR: () ->
    $('#qrcode').qrcode {
      render: 'canvas'
      fill: '#444'
      background: 'transparent'
      width: 100
      height: 100
      text: @qrcode
    }

  resize: () ->
    height = $(window).height()
    $('.video-container').height height
    lineHeight = $('.content-wrapper').height()
    $('.content').css {
      height: lineHeight
      marginTop: -lineHeight/2
    }

$ ->
  window.application = new Application()
